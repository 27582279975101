import React from "react";
import spotlineLogo from "../../logo.svg";
import "./footer.css";

const Footer = () => (
  <div className="spotline__footer section__padding" id="contacts">
    <div className="spotline__footer-heading">
      <h1 className="gradient__text">
        Do you want to step in to the future before others
      </h1>
    </div>

    <div className="spotline__footer-btn">
      <p>Request Early Access</p>
    </div>

    <div className="spotline__footer-links">
      <div className="spotline__footer-links_logo">
        {/* <img src={spotlineLogo} alt="spotline_logo" /> */}
        <p
          className="gradient__text__logo"
          style={{ fontSize: 40, fontWeight: 700 }}
        >
          Spotline
        </p>
        <p> All Rights Reserved</p>
      </div>
      <div className="spotline__footer-links_div">
        {/* ! Put social media platforms handles */}
        <h4>Links</h4>
        <p>Instagram</p>
        <p>Facebook</p>
        <p>Linkedin</p>
        <p>Twitter</p>
      </div>
      <div className="spotline__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
      </div>
      <div className="spotline__footer-links_div">
        <h4>Get in touch</h4>
        {/* <p>Crechterwoord K12 182 DK Alknjkcb</p> */}
        {/* <p>085-132567</p> */}
        <p>info@spotline.org</p>
      </div>
    </div>

    <div className="spotline__footer-copyright">
      <p>@2021 Spotline. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;
