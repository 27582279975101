import React from "react";
import Feature from "../../components/feature/Feature";
import "./WhatSpotline.css";

const Whatspotline = () => (
  <div
    className="spotline__whatspotline section__margin"
    id="features"
    name="whatspotline"
  >
    {/* <div className="spotline__whatspotline-feature">
      <Feature
        title="What is Spotline"
        text="We so opinion friends me message as delight. Whole front do of plate heard oh ought. His defective nor convinced residence own. Connection has put impossible own apartments boisterous. At jointure ladyship an insisted so humanity he. Friendly bachelor entrance to on by."
      />
    </div> */}
    <div className="spotline__whatspotline-heading">
      <h1 className="gradient__text">
        The possibilities are beyond your imagination
      </h1>
      {/* <p>Explore the Library</p> */}
    </div>
    <div className="spotline__whatspotline-container">
      <Feature
        title="Privacy"
        text="Your private information isn't for the advertisement companies to scrutiny. At Spotline, we take utmost care of your privacy."
      />
      <Feature
        title="Security"
        text="With Spotline,we store your personal data with the help of Blockchain to prevent data breach and make sure your data is only for you to see."
      />
      <Feature
        title="Award Mechanism"
        text="Tired of scrolling through the feed and ending up with empty hands? Don't worry, with you can earn while you scroll!"
      />
      <Feature
        title="User Friendly"
        text="Our app is very easy to use and you can easily navigate to earn while you scroll!"
      />
      <Feature
        title="Community Driven"
        text="Meet a community of users who are interested in the same stuff as you! Reward their boards and build beautiful boards with them."
      />
      <Feature
        title="Structured"
        text="Tired of not getting what you are looking for? With us, you have specific symbols (i.e. @ and #) for looking up people and boards respectively."
      />
    </div>
  </div>
);

export default Whatspotline;
