import React, { useEffect, useState } from "react";
import people from "../../assets/people.png";
import ai from "../../assets/ai.png";
import profile from "../../assets/profile.png";
import "./header.css";
import { getcount } from "../servers/user_reg";

const Header = () => {
  const [count, setCount] = useState(0);

  useEffect(async () => {
    const resp = getcount();
    const responseJson = await resp.then((e) => e.json()).then((resp) => resp);
    setCount(responseJson);
  }, []);

  return (
    <div className="spotline__header section__padding" id="home">
      <div className="spotline__header-content">
        <h1 className="gradient__text">
          Be where the world is{" "}
          <span className="gradient__text__logo">scrolling</span>.
        </h1>
        <p>
          Spotline is a coming of the age social media app where you can earn
          while you scroll! Send connections, Collaborate and make beautiful
          boards, Reward your favourite boards and build connections with a
          spectrum of users. Discover the future of Social Media with us!
        </p>

        {/* <div className="spotline__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button">Get Started</button>
      </div> */}

        <div className="spotline__header-content__people">
          <img src={people} />
          <p>{1600 + count} people requested access in the last 24 hours</p>
        </div>
      </div>

      <div className="spotline__header-image">
        <img src={profile} si />
      </div>
    </div>
  );
};

export default Header;
