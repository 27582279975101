import React from "react";
import Feature from "../../components/feature/Feature";
import "./features.css";

const featuresData = [
  {
    title: "Earn while you scroll ",
    text: "Enough of scrolling media and losing your productive hours! Build a profile on this new feature, connect with people and earn while you scroll. ",
  },
  {
    title: "Award people’s post! ",
    text: "All you have to do is collaborate and make beautiful boards, reward your favourite board and build connection with a spectrum of users.",
  },
  {
    title: "Withdraw money from gifted coins",
    text: "Make beautiful boards and get rewarded. You can withdraw money from the coins gifted to you as a reward on your board.",
  },
  {
    title: "Earning never been this easy",
    text: "Connect with the world with spotline and discover some new interests, meet new people and earn money.",
  },
];

const Features = () => (
  <div className="spotline__features section__padding" id="earn_coins">
    <div className="spotline__features-heading">
      <h1 className="gradient__text">
        Earn while you meet new people and discover a new world.
      </h1>
      <p>Request Early Access to Get Started</p>
    </div>
    <div className="spotline__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
