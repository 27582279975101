import React from "react";
import "./cta.css";

const CTA = () => (
  <div className="spotline__cta">
    <div className="spotline__cta-content">
      <p>Request Early Access to Get Started</p>
      <h3>Register Today & start exploring the endless possibilities.</h3>
    </div>
    <div className="spotline__cta-btn">
      <a type="button" href="#register">
        Get Started
      </a>
    </div>
  </div>
);

export default CTA;
