import React, { useState } from "react";
import img2 from "../../assets/img2.png";
import { early_access } from "../servers/user_reg";
import "./possibility.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Possibility = () => {
  const [email, setEmail] = useState("");
  const [resp, setResponse] = useState(
    "First 10k People will get upto 200 free coins."
  );

  const notify = (message) => {
    console.log({ message });
    toast("yeye");
  };

  const handleOnSubmit = async () => {
    if (email.length !== 0) {
      let resp = await early_access(email);
      console.log("yeye");
      console.log({ resp });
      console.log();
      if (resp.status >= 201 && resp.status <= 299) {
        setResponse("Yey! you are subscribe to spotline early access.");
        console.log("in");
        notify("Yey! you are subscribe to spotline early access.");
      } else if (resp.status >= 400 && resp.status <= 499) {
        setResponse(
          "You are already subscribed. share it with your friends and get rewards!"
        );
        console.log("in 400M RANGE");
        notify(
          "You are already subscribed. share it with your friends and get rewards!"
        );
      } else {
        setResponse(
          "Oops, Something went wrong. Please try again after sometime."
        );
        console.log("in 500 RANGE");
        notify("Something went wrong. Please try again after sometime.");
      }
      setEmail("");
    }
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    toast("yo");
    setEmail(e.target.value);
  };

  return (
    <div className="spotline__possibility section__padding" id="register">
      <div className="spotline__possibility-image">
        <img src={img2} alt="possibility" />
      </div>
      <div className="spotline__possibility-content">
        <h4>Request Early Access to Get Started</h4>
        <h1 className="gradient__text">
          The possibilities are <br /> beyond your imagination
        </h1>
        <div className="spotline__header-content__input">
          <input
            type="email"
            placeholder="Your Email Address"
            value={email}
            onChange={(event) => handleOnChange(event)}
          />
          <button type="button" onClick={() => handleOnSubmit()}>
            Get Started
          </button>
        </div>
        <h4>{resp}</h4>
      </div>
    </div>
  );
};

export default Possibility;
