import data from "../../Domains";

export async function early_access(email: string) {
  const response = await fetch(
    data.backendTestUrl + `/early_access?email=${email}`,
    {
      method: "POST",
      cache: "no-cache",
      credentials: "same-origin",
      body: JSON.stringify({ email: email }),
    }
  );
  return response;
}
export async function getcount(email: string) {
  const response = await fetch(data.backendTestUrl + `/early_access/count`, {
    method: "GET",
    cache: "no-cache",
    credentials: "same-origin",
  });
  return response;
}
